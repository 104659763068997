import React from 'react';
import styles from './MenuIcon.module.scss';

const MenuIcon = ({ isOpen }) => {
  return (
    <span className={styles.wrapper}>
      <span className={`${isOpen ? styles.activeTop : '' } ${styles.bar} ${styles.top}`} />
      <span className={`${isOpen ? styles.activeMiddle : '' } ${styles.bar} ${styles.middle}`} />
      <span className={`${isOpen ? styles.activeBottom : '' } ${styles.bar} ${styles.bottom}`} />
    </span>
  );
};

export default MenuIcon;
