import React from 'react';
import { Link } from 'gatsby';
import useHeaderScrolling from '../../hooks/headerScrolling';
import useState from '../../hooks/state';
import Anchor from '../Anchor/Anchor';
import styles from './Header.module.scss';
import logo from '../../assets/images/sauer-catering-logo.svg';
import title from '../../assets/images/sauer-catering-logo-title.svg';
import bioSiegel from '../../assets/images/bio-siegel-eg-oeko-vo-deutschland.png';

const Header = () => {
  const [isScrolling] = useHeaderScrolling();
  const { isOpen } = useState('sidebar');
  const NavLink = (typeof window !== 'undefined' && (window.location.pathname === '' || window.location.pathname)) === '/' ? Anchor : Link;

  return (
    <header className={`${styles.header} ${isScrolling ? styles.headerIsScrolling : ''} ${isOpen ? styles.headerIsOpen : ''}`}>
      <div className={`container container--fullWidth ${styles.container}`}>
        <a href="/" className={styles.logo}>
          <img className={isScrolling ? styles.logoIsScrolling : ''} src={logo} alt="Sauer" />
          <img className={isScrolling ? styles.titleIsScrolling : ''} src={title} alt="Catering & Ladenlokal am Sorpesee" />
        </a>
        <nav className={styles.navigation}>
          <Link to="/weihnachts-menue" anchor="christmas" className={styles.link}>Weihnachtsmenü</Link>
          <NavLink to="/#scroll=catering" anchor="catering" className={styles.link}>Catering</NavLink>
          <NavLink to="/#scroll=menu-suggestions" anchor="menu-suggestions" className={styles.link}>Menü Vorschläge</NavLink>
          {/* <NavLink to="/#scroll=quality-information" anchor="quality-information" className={styles.link}>Qualitätsinfo</NavLink> */}
          <NavLink to="/#scroll=butchers" anchor="butchers" className={styles.link}>Ladenlokal</NavLink>
          <NavLink to="/#scroll=contact" anchor="contact" className={styles.link}>Kontakt</NavLink>
        </nav>
        <img className={`${styles.bioSiegel} ${isScrolling ? styles.bioSiegelIsScrolling : ''}`} src={bioSiegel} alt="Bio nach EG-Öko-Verordnung" />
        <Link to="/online-menue-planer" className={`button button--ghost ${styles.quickLink}`}>Menü-Planer</Link>
      </div>
    </header>
  );
};

export default Header;
