import React from 'react';
// import PropTypes from 'prop-types';
import styles from './Banner.module.scss';

const Banner = () => (
  <div className="section">
    <div className={styles.banner} />
  </div>
);

Banner.propTypes = {};

Banner.defaultProps = {};

export default Banner;
