import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import useState from '../../hooks/state';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Sidebar from '../Sidebar/Sidebar';
import CookieConsent from '../CookieConsent/CookieConsent';
import Snow from '../Snow/Snow';
// import ChatContainer from '../../container/ChatBot/ChatContainer.tsx';
import styles from './Layout.module.scss';

function Layout(props) {
  const { children } = props;
  const { isOpen } = useState('sidebar');
  const activeClass = isOpen ? styles.sidebarIsOpen : styles.sidebarIsClosed;

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <>
          <Header />
          <div className={`${styles.wrapper} ${activeClass}`}>
            <main>{children}</main>
            <Footer />
          </div>
          <Sidebar />
          {/* <ChatContainer /> */}
          <CookieConsent />
          <Snow />
          <a
            href="/weihnachts-menue"
            className={styles.christmas__truck}
          >
            <img
              src="/images/christmas/sauer-christmas-car.svg"
              alt="Weihnachts Truck"
            />
          </a>
        </>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
