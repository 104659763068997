import React from 'react';

const SvgComponent = (props) => (
  <svg
    id="prefix__Ebene_1"
    x={0}
    y={0}
    viewBox="0 0 120 120"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.prefix__sthand0{fill:#c2ab62}'}</style>
    <path
      className="prefix__sthand0"
      d="M102.9 92.9c-5.7 0-11.5-.9-16.9-2.6l-.5-.2.2-.5c.1-.4.5-.7.8-1 .2-.2.5-.4.5-.5l.9.3c-.1.4-.5.7-.8 1l-.1.1c10.2 3.1 21.7 3.1 31.8 0-2.7-1.6-5.2-3.4-7.5-5.4-.5-.5-.7-1.3-.4-2 1.3-2.5 2.3-5.2 3-7.8-3.5 1.1-7.1 1.6-10.7 1.6v-1h.1c3.6 0 7.1-.5 10.5-1.6.4-.1.7-.1.9.2.2.2.3.6.2 1-.7 2.7-1.8 5.5-3.1 8.1-.1.2-.1.6.1.8 2.3 2 4.8 3.8 7.5 5.4.5.3.5.7.5.9 0 .2-.2.6-.9.9-5.1 1.5-10.6 2.3-16.1 2.3zM16.9 93.1c-5.4 0-10.7-.8-15.9-2.5-.7-.2-.9-.6-.9-.9 0-.2 0-.6.5-.9 2.7-1.6 5.2-3.4 7.5-5.4.2-.2.3-.5.1-.8-1.3-2.6-2.3-5.3-3.1-8.1-.1-.4 0-.8.2-1 .2-.2.6-.3.9-.2 2.9.9 5.9 1.4 8.9 1.6v1c-3 .1-6.1-.5-9-1.4.7 2.7 1.7 5.3 3 7.8.3.6.2 1.5-.4 2-2.3 2-4.8 3.8-7.5 5.4 10.2 3.2 21 3.2 31.2 0l-.2-.5.9-.3.5 1.4-.5.2c-5.2 1.8-10.7 2.6-16.2 2.6zM1 89.8z"
    />
    <path
      className="prefix__sthand0"
      d="M59 91.8c-15.7 0-31.4-2.5-46.7-7.4l-.5-.2 5.6-17.4.6.2c5.2 1.7 10.7 3.1 16.1 4.1 21.9 4.2 44.7 2.8 66-4.1l.5-.2 5.6 17.4-.5.2c-15.3 4.9-31 7.4-46.7 7.4zm-45.9-8.2c30 9.7 61.8 9.7 91.8 0l-5-15.5c-21.3 6.8-44.1 8.2-66 4-5.3-1-10.6-2.4-15.7-4l-5.1 15.5z"
    />
    <path
      className="prefix__sthand0"
      d="M33.6 89.4c4.2 10.8 14.8 18.1 26.5 18.1 11.9 0 22.5-7.4 26.6-18.5-.8.1-1.5.3-2.3.4-4.2 9.8-13.7 16.1-24.3 16.1-10.5 0-19.9-6.2-24.2-15.7-.8-.1-1.6-.2-2.3-.4zM35.7 46.7c1.5 0 2.8.6 3.9 1.7 1.8 1.7 2.9 4.8 2.9 7.9v14.3c0 .5.4 1 1 1s1-.4 1-1V24.4c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v33.1c0 .5.4 1 1 1s1-.4 1-1V19c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v38.5c0 .5.4 1 1 1 .5 0 1-.4 1-1V24.4c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v33.1c0 .5.4 1 1 1 .5 0 1-.4 1-1v-20c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v33.2c.7-.1 1.3-.3 2-.4V37.5c0-3.6-2.9-6.5-6.5-6.5-1.5 0-2.8.5-4 1.4l-.5.4v-8.5c0-3.6-2.9-6.5-6.5-6.5-1.5 0-2.8.5-4 1.4l-.5.4V19c0-3.6-2.9-6.5-6.5-6.5s-6.5 2.9-6.5 6.5v.7l-.5-.4c-1.1-.9-2.5-1.4-4-1.4-3.6 0-6.5 2.9-6.5 6.5v24.4L42 48c-.3-.4-.6-.8-1-1.1-1.5-1.5-3.4-2.3-5.4-2.2-2.4 0-4 1.5-4 3.6v22.5h.2c.6.1 1.2.3 1.8.4V48.3c0-1 .7-1.6 2.1-1.6z"
    />
    <path
      className="prefix__sthand0"
      d="M12.5 83.9l14.4 7.6 7.3-1.2-.4-1.2zM105 84.1l-12.4 7.3-6.4-1.7.4-1.1zM33.8 84.5l.6-3.4-1.5-.3-.6 3.4-1.2-.2 1.4-7.8 1.2.2-.6 3.3 1.5.3.6-3.3 1.2.2-1.4 7.8-1.2-.2zM41.4 85.6l-.1-1.6-1.8-.2-.5 1.5-1.3-.2 3-7.6 1.2.2.9 8.1-1.4-.2zm-.5-6.1l-1.1 3.2 1.3.2-.2-3.4zM48.4 86.3l-1.5-4.8-.4 4.7-1.2-.2.6-7.9 1.1.1 1.5 4.8.4-4.7 1.2.1-.6 7.9h-1.1zM56.3 86c-.3.4-.8.6-1.6.6l-2-.1.3-7.9 2 .1c.8 0 1.2.3 1.5.7.5.6.5 1.5.5 3.3-.1 1.8-.2 2.7-.7 3.3zm-.8-5.9c-.1-.2-.3-.3-.6-.3h-.7l-.2 5.6h.7c.3 0 .5-.1.6-.3.3-.4.3-1.1.3-2.5.2-1.3.2-2.1-.1-2.5zM64 86.6l-.1-4.9-1 3.3h-.7l-1.1-3.2.1 4.9H60l-.2-7.9h1.1l1.6 4.1 1.4-4.2H65l.2 7.9H64zM71.6 86.2l-.4-1.5-1.8.1-.2 1.6-1.3.1 1.3-8 1.2-.1 2.5 7.7-1.3.1zM70 80.3l-.4 3.4 1.4-.1-1-3.3zM79 84.7c-.3.4-.7.7-1.4.8l-2 .3-1-7.8 2-.3c.7-.1 1.2.1 1.6.5.6.5.8 1.4 1 3.2.2 1.7.3 2.6-.2 3.3zM77.4 79c-.1-.2-.4-.3-.7-.2l-.7.1.7 5.6.7-.1c.3 0 .5-.2.6-.4.2-.4.1-1.2-.1-2.5-.1-1.4-.3-2.1-.5-2.5zM82.7 84.8L81.3 77l3.3-.6.2 1.1-2 .4.4 2.2 1.8-.3.2 1.1-1.8.3.4 2.3 2-.4.2 1.1-3.3.6z"
    />
  </svg>
);

export default SvgComponent;
