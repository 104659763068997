import React from 'react';
import PropTypes from 'prop-types';
import './Snow.scss';

function Flake({ position, size, time, delay }) {
  return (
    <div
      className="Flake"
      style={{
        left: `${position}%`,
        width: `${size}px`,
        height: `${size}px`,
        animationDuration: `${time}s`,
        animationDelay: `${delay}s`,
      }}
    />
  );
}

Flake.propTypes = {
  position: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  time: PropTypes.number.isRequired,
  delay: PropTypes.number.isRequired,
};

function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function Snow() {
  return (
    <div className="Snow">
      {Array(20).fill(0).map((_, index) => (
        <Flake
          key={index}
          position={random(0, 100)}
          size={random(4, 8)}
          time={random(12, 16)}
          delay={index}
        />
      ))}
    </div>
  );
}

export default Snow;
