import React from 'react';
import { Link } from 'gatsby';
import FamilienBetrieb from '../Icons/FamilienBetrieb';
import Regionales from '../Icons/Regionales';
import Handmade from '../Icons/Handmade';
import './Footer.scss';

const Footer = () => (
  <footer id="contact" className="Footer">
    <div className="Footer__wrapper">
      <div className="container">
        <div className="row">
          <div className="col col--lg-4">
            <a href="/" className="Footer__logo">
              <img src="/images/sauer-catering-logo-weiss.svg" alt="Sauer - Catering & Ladenlokal am Sorpesee" />
            </a>
            <p>
              Zum Rosengarten 3
              <br />
              59846 Sundern / Langscheid
            </p>
            <p>
              Tel 0 29 35 / 25 93
              <br />
              Mobil 0172 / 273 94 58
            </p>
            <p>
              <a href="mailto:info@sauer-catering.de">info@sauer-catering.de</a>
              <br />
              www.sauer-catering.de
            </p>
          </div>
          <div className="Footer__icons col col--lg-8">
            <FamilienBetrieb className="Footer__icon" />
            <Regionales className="Footer__icon" />
            <Handmade className="Footer__icon" />
          </div>
        </div>
      </div>
    </div>
    <div className="Footer__navigation">
      <Link to="/datenschutz" className="Footer__link">Datenschutz</Link>
      <Link to="/impressum" className="Footer__link">Impressum</Link>
    </div>
  </footer>
);

export default Footer;
