import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, disableTitleTemplate, canonical }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  const metaDescription = description || site.siteMetadata.description;
  const links = [];

  if (canonical) {
    links.push({
      href: canonical,
      rel: 'canonical',
    });
  }

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={disableTitleTemplate ? `%s` : `%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: 'Sauer - Catering & Ladenlokal am Sorpesee',
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        ...meta,
      ]}
      link={links}
    />
  );
}

SEO.defaultProps = {
  lang: 'de',
  meta: [],
  description: '',
  canonical: null,
  disableTitleTemplate: false,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  disableTitleTemplate: PropTypes.bool,
  canonical: PropTypes.string,
};

export default SEO;
