import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import styles from './CookieConsent.module.scss';

const getFromStorage = () => {
  if (typeof window !== 'undefined') {
    const storage = localStorage.getItem('cookieConsent');
    return !!storage;
  }
  return false;
};

const setToStorage = () => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('cookieConsent', 'true');
  }
};

const CookieConsent = () => {
  const classNames = [styles.cookieConsent];
  const [accepted, setAccepted] = useState(getFromStorage());

  const acceptHandler = () => {
    setToStorage();
    setAccepted(true);
  };

  return (
    <>
      {accepted && (
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-52968631-3" />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'UA-52968631-3');
            `}
          </script>
        </Helmet>
      )}
      {!accepted && (
        <aside
          className={classNames.join(' ')}
          role="dialog"
          aria-modal="true"
        >
          <div className={`container ${styles.container}`}>
            <h4 className={styles.title}>Datenschutz Hinweis</h4>
            <p className={styles.subline}>
              Wir verwenden Google Analytics, um Zugriffe auf unsere Website anonym zu analysieren.
              Weitere Informationen zum <Link to="/datenschutz">Datenschutz</Link> oder zu unserem <Link to="/impressum">Impressum</Link>.
            </p>
            <button
              type="button"
              onClick={acceptHandler}
              aria-label="Cookies akzeptieren"
              className={`button button--ghost ${styles.button}`}
            >
              Okay
            </button>
          </div>
        </aside>
      )}
    </>
  );
};

export default CookieConsent;
