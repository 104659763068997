import { useEffect, useState } from 'react';

const SCROLL_HEIGHT = 100;

function useHeaderScrolling() {
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollHandler = () => {
    const offset = window.pageYOffset || document.documentElement.scrollTop;

    if (offset > SCROLL_HEIGHT) {
      setIsScrolling(true);
      return;
    }

    if (offset <= SCROLL_HEIGHT) {
      setIsScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return [isScrolling];
}

export default useHeaderScrolling;
