import React from 'react';
import PropTypes from 'prop-types';

function Anchor({ children, anchor, onClick, className }) {
  const scroll = () => {
    const element = document.getElementById(anchor);
    const offsetPosition = element.offsetTop - 110;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <a
      href={`#${anchor}`}
      className={className}
      onClick={(e) => {
        e.preventDefault();
        scroll();
        if (onClick) onClick();
      }}
    >
      {children}
    </a>
  );
}

Anchor.propTypes = {
  anchor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Anchor.defaultProps = {
  className: null,
  onClick: null,
};

export default Anchor;
